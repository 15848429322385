








































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MedicalBusiness, Clinic } from '@/interface/api'

@Component({
  name: 'TreatmentReservationCompletePage'
})
export default class TreatmentReservationCompletePage extends Vue {
  @Prop({ default: '' }) private medicalBusinessId!: string
  @Prop({ default: () => null }) private medicalbusiness!: MedicalBusiness
  @Prop({ default: false }) private isAppPlan!: boolean
  @Prop({ default: false }) private targetClinic!: Clinic
}
